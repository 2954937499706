'use client';

import { PropsWithChildren } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import clientConfig from '@/lib/config/client-config';

interface ProviderProps extends PropsWithChildren {}

const RecaptchaProvider = ({ children }: ProviderProps) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={clientConfig.recaptcha.key}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default RecaptchaProvider;
