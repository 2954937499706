'use client';

import { GoogleTagManager } from '@next/third-parties/google';
import { useEffect, useState } from 'react';

import { useAuthContext } from '@/app/[locale]/_providers/auth';
import { User } from '@/domain/user/services/types';
import analyticsManager from '@/lib/analytics';
import { UserInfoPayload } from '@/lib/analytics/types';
import clientConfig from '@/lib/config/client-config';
import { usePathname } from '@/lib/translation/hooks';

function computeStatusFromUser(user: User): UserInfoPayload['user_status'] {
  if (user.hasAlreadyInvested) {
    return 'investisseur';
  }

  if (user.isVerified) {
    return 'dossier validé';
  }

  if (user.isFolderCompleted) {
    return 'dossier complété';
  }

  return 'inscrit';
}

function getUserPayloadFromUser(user?: User) {
  if (user) {
    return {
      user_info: user?.isCompany
        ? ('entreprise' as const)
        : ('particulier' as const),
      user_isConnected: !!user,
      user_status: computeStatusFromUser(user),
    };
  }
  return {
    user_info: undefined,
    user_isConnected: false,
    user_status: undefined,
  };
}

const AnalyticScriptAndInitialDataLayerPush = () => {
  const pathname = usePathname();
  const { user } = useAuthContext();
  const [isInitialDataLayerPushed, setIsInitialDataLayerPushed] =
    useState(false);

  useEffect(() => {
    if (!isInitialDataLayerPushed && clientConfig.analytics.enabled) {
      analyticsManager.client.sendInitialDataLayerPush(
        getUserPayloadFromUser(user),
      );
      setIsInitialDataLayerPushed(true);
    }
  }, [isInitialDataLayerPushed, pathname, user]);

  useEffect(() => {
    if (clientConfig.analytics.enabled && isInitialDataLayerPushed) {
      analyticsManager.client.sendEvent({
        event: 'page_view',
        page_view: '', // for now we don't have access to the title of the page from here, we would need a global and accessible configuration
        page_location: clientConfig.host + pathname,
        ...getUserPayloadFromUser(user),
      });
    }
  }, [pathname, isInitialDataLayerPushed, user]);

  return clientConfig.analytics.enabled && isInitialDataLayerPushed ? (
    <GoogleTagManager
      gtmId={clientConfig.analytics.gtm.id}
      preview={clientConfig.analytics.gtm.preview}
      auth={clientConfig.analytics.gtm.auth}
    />
  ) : null;
};

export default AnalyticScriptAndInitialDataLayerPush;
